import React, { useState, useEffect, useContext, useMemo } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import querystring from 'querystring';
import Context from 'container';
import Menu from 'components/Menu';
import DropdownList from 'components/DropdownList';
import { defaultLanguage, fieldTranslation } from 'components/constants';
import { getTopNavigation, getMenus } from './services';
import { map, filter, isEmpty } from 'lodash';
import { isSSR } from 'scripts/utils';
import './index.scss';
import useLanguage from 'hooks/useLanguage';
import useDeviceInfo from 'hooks/useDeviceInfo';
import { createContactUsModal } from 'components/ContactUs';
import Consult from 'components/IconWrapper/consult';
import MenuIcon from 'components/IconWrapper/menu';
import MobileMenu from 'components/MobileMenu';

const menus1688 = [
  { name: '成为采购商', link: '/go1688/purchaser' },
  { name: '成为供应商', link: '/go1688/supplier' }
]

export default function Ceiling() {
  const { dispatch, currentSite = {}, theme, sites = [], host, lang, ...others} = useContext(Context);
  const [menus, setMenus] = useState([]);
  const { i18n } = useLanguage();
  const [is1688, setIs1688] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const deviceInfo = useDeviceInfo()
  const { bd_vid, ext_info } = deviceInfo
  const afterUrl = useMemo(()=> {
    const temp = {}
    if (bd_vid) {
      temp.bd_vid = bd_vid
    }
    if (ext_info) {
      temp.ext_info = ext_info
    }
    return isEmpty(temp) ? '' :  ('?' + (querystring.stringify(temp)))
  }, [bd_vid, ext_info])

  const showContact = () => {
    createContactUsModal();
  };
  useEffect(() => {
    if (isSSR) {
      return
    }
    setTimeout(() => {
      const tempIs1688 = typeof window !== 'undefined' && window?.location?.pathname.startsWith('/go1688')
      if (tempIs1688) {
        const path = window?.location?.pathname.replace('/go1688', '') || '/supplier'
        window.location.href = `https://ycx.terminus.io${path}`
      }
      setIs1688(tempIs1688)
    }, 50);
  }, [])
  // 不统计回传页面访问
  // useEffect(() => {
  //   if (!deviceInfo.bd_vid) {
  //     return
  //   }
  //   const logidUrl = window.location.href
  //   trafficStatic({ ...deviceInfo, logidUrl }).then(() => {
  //     console.log('统计初始化完成: ', logidUrl)
  //   })
  // }, [deviceInfo.deviceId, deviceInfo.deviceType])

  useEffect(() => {
    (async () => {
      if (!currentSite.id) return;
      const topNav = await getTopNavigation({ site: { id: currentSite.id } });
      const result = await getMenus(currentSite.id);
      const _menus = topNav.map((single) => ({
        name: single.displayName,
        link: single.pageAddress + afterUrl,
        children: single.pageAddress === '/about-us' ? getAboutUs() : getChild(single, result)
      }));
      setMenus(_menus);

      dispatch({ topNav });
    })();
  }, [currentSite.id, afterUrl]);

  function onLanguageChange(mark) {
    let target;
    if (mark === defaultLanguage) {
      target = `//${host.replace(`${lang}.`, '')}`;
    } else {
      target = host.indexOf(lang) ? `//${mark}.${host}` : `//${host.replace(lang, mark)}`;
    }
    window.location.href = target;
  }

  const getAboutUs = () => {
    return [
      {
        children: [
          {
            name: i18n(fieldTranslation, '新闻动态'),
            link: '/about-us/news' + afterUrl
          },
          {
            name: i18n(fieldTranslation, '品牌活动'),
            link: '/activity' + afterUrl
          },
          {
            name: i18n(fieldTranslation, '公司介绍'),
            link: '/about-us' + afterUrl
          },
          {
            name: i18n(fieldTranslation, '公司刊物'),
            link: '/periodical' + afterUrl
          },
          {
            name: i18n(fieldTranslation, '多媒体资源'),
            link: '/media' + afterUrl
          },
          {
            name: i18n(fieldTranslation, '加入我们'),
            link: '/join-us' + afterUrl
          },
          {
            name: i18n(fieldTranslation, '联系我们'),
            onClick: showContact
            // link: '/join-us'
          }
        ]
      }
    ];
  };

  function getChild(nav, children) {
    let result;
    children.forEach((child) => {
      if (child.title === nav.navigationName) {
        result = filter(
          map(child.data, (d, i) => {
            if (d.productList) {
              return {
                icon: d.proLogo,
                name: d.name,
                desc: d.naviDesc,
                link: d.pcResource ? (`/product-category/${d.id}` + afterUrl) : undefined,
                children:
                  d.productList &&
                  d.productList.map((plate) => ({
                    name: plate.name,
                    desc: plate.naviDesc,
                    link: `/product/${plate.id}` + afterUrl
                  }))
              };
            } else if (d.solutions) {
              return {
                icon: d.indusIcon,
                name: d.name,
                desc: d.naviDesc,
                link: `/solution#${i}` + afterUrl,
                children:
                  d.solutions &&
                  d.solutions.map((solution) => ({
                    name: solution.name,
                    desc: solution.naviDesc,
                    link: `/solution/${solution.id}` + afterUrl
                  }))
              };
            }
          }),
          (d) => !!d
        );
      }
    });

    return result;
  }

  return (
    <>
      <div
        id="ceiling"
        className={classnames('comp-ceiling hidden-in-mobile', {
          // show: visible,
          // "has-background": hasBackground,
          [theme]: is1688 ? false : theme,
          light: is1688,
        })}
      >
        <div className="ceiling-main">
          <div className="filter-blur"></div>
          <img className="logo-search" src="/static/images/logo-all.png" alt="杭州端点网络科技有限公司" />
          <div className="ceiling-content">
            <h1>
              <Link to={is1688 ? '/go1688' : '/'} title="杭州端点网络科技有限公司">
                <img className="logo-light" src={`/static/images/${is1688 ? 'logo-1688' : 'logo'}.png`} style={is1688 ? { height: 36, width: 220 } : {}} alt="杭州端点网络科技有限公司" />
                <img className="logo-dark" src={`/static/images/${is1688 ? 'logo-1688' : 'logo-dark'}.png`} alt="杭州端点网络科技有限公司" />
              </Link>
            </h1>
            <div className="ceiling-content-wrapper">
              <Menu className="ceiling-menu" data={is1688 ? menus1688 : menus} theme={theme} />
              {is1688 && <div className='phone-wrap'>
                <div>
                  <img className='phone-img' src='/static/images/phone.png' alt="联系方式" />
                  <span className='phone-text'>0571-56131922 / 0571-56131923</span>
                </div>
                <a className='notice-a' target='_blank' href='https://www.aliyun.com/noticelist/articleid/1071134207.html'>
                  <img className='notice-img' src='/static/images/notice-1688.png' alt="联系方式" />
                </a>
              </div>}
              {!is1688 && sites instanceof Array && sites.length > 1 ? (
                <span className="lang">
                  <DropdownList
                    id="lang-select"
                    options={sites.map((site) => ({
                      icon: site.logo,
                      label: site.title,
                      value: site.mark
                    }))}
                    value={lang}
                    onChange={onLanguageChange}
                  />
                </span>
              ) : null}
            </div>
          </div>
        </div>
        {/* <div onClick={showContact} className="consult-button">
          {lang === 'cn' ? '立即咨询' : 'Consult'}
        </div> */}
      </div>
      <div className="mobile-ceiling hidden-in-pc">
        <MenuIcon onClick={() => setMobileMenuOpen(true)} size={20} strokeWidth={1} />
        <MobileMenu
          data={map(menus, (menu) => {
            if (menu.children) {
              return { ...menu, group: true };
            } else {
              return menu;
            }
          })}
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
          sites={sites}
          onLanguageChange={onLanguageChange}
        />
        <Link to={"/" + afterUrl} title="杭州端点网络科技有限公司">
          <img className="mobile-ceiling-logo" src="/static/images/logo.png" alt="杭州端点网络科技有限公司" />
        </Link>
        <Consult size={20} strokeWidth={1} onClick={() => createContactUsModal()} />
      </div>
    </>
  );
}
