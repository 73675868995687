import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import usePlatform from 'hooks/usePlatform';
import useLanguage from 'hooks/useLanguage';
import Helmet from 'components/Helmet';
import Banner from 'components/Banner';
import { BANNER_TYPE, fieldTranslation } from 'components/constants';
import { getSolutionDetail } from './services';
import { Resource } from 'components/types';
import MainTitle from 'components/Common/main-title';
import { Case } from 'pages/Cases/cases-list';
import { isEmpty, map } from 'lodash';
import { oss } from 'scripts/utils';
import './detail.scss';
import Slider, { Settings } from 'react-slick';
import cn from 'classnames';
import CircleButton from 'components/Common/circle-button';
import { CursorPagination } from 'components/Common/cursor-pagination';
import Consult from 'components/Consult';
import { CaseSwitch } from 'pages/Product/detail';
import classNames from 'classnames';

interface IndustryState {
  id: number;
  title: string;
  desc: string;
  resource: Resource;
}

interface BusinessScene {
  id: number;
  desc: string;
  resource: Resource;
  title: string;
}

interface SolutionDetail {
  pcResource: Resource;
  mobileResource: Resource;
  name: string;
  desc: string;
  industryName?: string
  industry: {
    jumpBO: {
      buttonTitle: string;
      attachment: Resource;
    };
  };
  jumpBO: {
    buttonTitle: string;
    attachment: Resource;
  };
  industryState: IndustryState[];
  exampleSolutionRelationBOList: Case[];
  businessScene: BusinessScene[];
  businessValue: any[]
  solutions: any[]
}

function SolutionDetailPage() {
  const { isPc } = usePlatform() || {};
  const { i18n } = useLanguage();
  const params = useParams<{ id: string }>();
  const { id } = params;
  const [data, setData] = useState<Partial<SolutionDetail>>({});
  const [activeValueIndex, setActiveValueIndex] = useState(0);
  const mainSliderRef = useRef<Slider | null>(null);
  const picSliderRef = useRef<Slider | null>(null);
  const business = data.businessScene ?? [];

  const contentToShow = isPc ? 2 : 1;
  const contentSlidesToShow = business === undefined ? 0 : business.length > 1 ? contentToShow : 1;

  const sliderContentSetting = useRef<Settings>({
    infinite: true,
    vertical: true,
    verticalSwiping: true,
    centerMode: false,
    swipeToSlide: true,
    slidesToScroll: 1,
    arrows: false,
    slidesToShow: 1,
    initialSlide: 0,
    beforeChange: (_: number, index: number) => {
      setActiveValueIndex(index);
    }
  });

  const sliderPicSetting: Settings = {
    vertical: true,
    touchMove: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    arrows: false
  };

  let files: { url: string }[] = [];
  const { pcResource, mobileResource } = data;
  if (pcResource || mobileResource) {
    if (isPc) {
      files = pcResource?.files || [];
    } else {
      files = mobileResource?.files || [];
    }
  }

  useEffect(() => {
    if (isPc) {
      sliderContentSetting.current.vertical = true;
      sliderContentSetting.current.verticalSwiping = true;
    } else {
      sliderContentSetting.current.vertical = false;
      sliderContentSetting.current.verticalSwiping = false;
    }
    sliderContentSetting.current.slidesToShow = contentSlidesToShow;
  }, [contentSlidesToShow, isPc]);

  useEffect(() => {
    (async () => {
      if (id) {
        const result = await getSolutionDetail(id);
        setData(result as unknown as SolutionDetail);
      }
    })();
  }, [id]);

  const industryState = data?.industryState;
  const businessValue = data?.businessValue?.[0]
  const solutionValue = data?.solutions?.[0]

  return (
    <div className='solution-detail-wrap'>
      <Helmet title={`${data.name}-端点-数字化转型-全链路解决方案`} description={data.desc} />
      <Banner
        covered
        type={BANNER_TYPE.SOLUTION}
        paddingTop={isPc ? 280 : 100}
        config={{
          src: files[0]?.url,
          title: data.name,
          legend: data.desc,
          operation: { jumpBO: data.jumpBO, industry: data.industry?.jumpBO }
        }}
      />
      <div className={classNames('solution-detail-now', { hidden: isEmpty(industryState) })}>
        <MainTitle className="now-title" main={i18n(fieldTranslation, data?.industryName || '行业洞察')} />
        <div
          className={classNames('industry-group', {
            'grid-col-2': industryState && industryState.length <= 2
          })}
        >
          {map(industryState, (state) => {
            return (
              <div key={state.id} className="solution-detail-now-item">
                <img width={32} height={32} src={oss(state.resource?.files?.[0]?.url, 64, 64)} alt={state.title} />
                <div className="now-detail">
                  <div className="now-detail-title">{state.title}</div>
                  <div className="now-detail-desc line-clamp-6 pre-line" title={state.desc}>{state.desc}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {solutionValue && <Banner
        showAdvantage={false}
        covered={false}
        size="large"
        type='PRODUCT'
        paddingTop={1}
        config={{
          src: solutionValue?.resourceUrl,
          title: solutionValue?.title,
          subheading: solutionValue?.desc,
          legend: solutionValue?.desc,
        }}
      />}
      {business.length > 0 ? (
        <div className={classNames('solution-detail-scenes', { hidden: isEmpty(business) })}>
          <MainTitle className="scenes-title" main={i18n(fieldTranslation, '核心场景')} />
          <div className="solution-values-wrapper">
            {business.length > 1 ? (
              <div className="carousel-side hidden-in-mobile">
                <div className="side-circles">
                  <CursorPagination
                    onItemClick={(i) => mainSliderRef.current?.slickGoTo(i)}
                    vertical={true}
                    activeIndex={activeValueIndex}
                    count={business.length}
                  />
                </div>
                <div className="side-buttons">
                  <CircleButton onClick={mainSliderRef.current?.slickPrev} type="top" />
                  <CircleButton onClick={mainSliderRef.current?.slickNext} type="bottom" />
                </div>
              </div>
            ) : null}

            <div className={classNames('carousel-content')}>
              <Slider
                {...sliderContentSetting.current}
                asNavFor={picSliderRef.current ?? undefined}
                ref={mainSliderRef}
                className="main-content-wrapper"
              >
                {map(business, (value, index) => {
                  const pic = value.resource?.files?.[0]?.url;
                  return (
                    <div
                      key={index}
                      className={cn('main-content', { 'main-content-active': activeValueIndex === index })}
                      onClick={() => mainSliderRef.current?.slickGoTo(index)}
                    >
                      <main>
                        <div className='main-content-img-wrapper hidden-in-pc'>
                          <img className="main-content-img" src={oss(pic,  undefined, 256)} alt="" />
                        </div>
                        <div className="main-content-title" title={value.title}>{value.title}</div>
                        <div
                          className="main-content-desc line-clamp-4"
                          title={value.desc}
                          dangerouslySetInnerHTML={{__html: value.desc?.replace('\n', '<br />')}}
                        ></div>
                      </main>
                    </div>
                  );
                })}
              </Slider>
              <CursorPagination
                className="hidden-in-pc flex-h-center mobile-pagination"
                onItemClick={(i) => mainSliderRef.current?.slickGoTo(i)}
                activeIndex={activeValueIndex}
                count={business.length}
              />
            </div>
            <div className={classNames('carousel-content hidden-in-mobile')}>
              <Slider
                {...sliderPicSetting}
                asNavFor={mainSliderRef.current ?? undefined}
                ref={picSliderRef}
                className="main-pic-wrapper"
              >
                {map(business, (b, index) => {
                  const pic = b.resource?.files?.[0]?.url;
                  return (
                    <div className={cn('pic-content')} key={index}>
                      <img src={oss(pic, undefined, 592)} alt={b.title} />
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div className="values-mask hidden-in-mobile"></div>
          </div>
        </div>
      ) : null}
      {businessValue && <Banner
        showAdvantage={false}
        covered={false}
        size="large"
        type='PRODUCT'
        paddingTop={1}
        config={{
          src: businessValue?.resourceUrl,
          title: businessValue?.title,
          subheading: businessValue?.desc,
          legend: businessValue?.desc,
        }}
      />}
      <div className={classNames('solution-detail-cases', { hidden: isEmpty(data?.exampleSolutionRelationBOList) })}>
        <MainTitle className="cases-title" main={i18n(fieldTranslation, '客户成功案例')} />
        <CaseSwitch className="solution-cases" data={data?.exampleSolutionRelationBOList} />
      </div>

      <Consult isDetail={true} />

    </div>
  );
}

export default SolutionDetailPage;
