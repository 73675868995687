import axios from 'axios';

export function getTopNavigation(params) {
  return axios.post('/api/internal/topNavigation/list', params)
}

export function getMenus(id) {
  return axios.post('/api/internal/topheader', { id });
}
// { logidUrl, deviceType, deviceId }
export function trafficStatic(params) {
  if (!params.deviceType || !params.deviceId || !params.logidUrl) {
    return Promise.reject();
  }
  return axios.post('/api/internal/traffic/access', params);
}
