import React, { useState, useContext } from 'react';
import Context from 'container';
import FormCreator from 'components/FormCreator';
import Toast from 'components/Toast';
import { EMAIL_REGEXP, PHONE_REGEXP } from 'components/constants';
import { submitForm } from './services';
import useDeviceInfo from 'hooks/useDeviceInfo';

const MAPPING = {
  EMAIL: {
    type: 'INPUT',
    validator: [
      {
        pattern: EMAIL_REGEXP,
        message: '请输入正确的邮箱'
      }
    ]
  },
  PHONE: {
    type: 'INPUT',
    validator: [
      {
        pattern: PHONE_REGEXP,
        message: '请输入正确的手机号码'
      }
    ]
  }
};

const getFormTypeSource = (pathname = '') => {
  if (pathname.includes('product')) {
    return 'PRODUCT'
  }
  if (pathname.includes('case')) {
    return 'EXAMPLE'
  }
  if (pathname.includes('solution')) {
    return 'SOLUTION'
  }
  if (pathname.includes('periodical')) {
    return 'CORPORATE_PUBLICATION'
  }
  return 'INDEX_MODEL'
}

export default function EnterForm({ className, formId, formConfig = [], onSuccess, onClose }) {
  const [disabled, setDisabled] = useState(false);
  const { currentSite = {} } = useContext(Context);
  const deviceInfo = useDeviceInfo()

  // const { preview } = useParams() ?? {};

  async function onSubmitHandle(values) {
    setDisabled(true);
    // if (preview) {
    //   Toast.info('预览表单，不能提交');
    //   return false;
    // }
    const fieldValues = {};
    Object.keys(values).forEach((key) => {
      const value = values[key];

      if (!key.indexOf('OTHER__')) {
        const [prefix, id] = key.split('__');
        const fieldValue = fieldValues[id];
        if (fieldValue instanceof Object) {
          fieldValues[id].value = fieldValue.value.replace(prefix, `其他（${value}）`);
        }
      } else {
        fieldValues[key] = {
          formFieldBO: { id: key },
          value: value instanceof Array ? value.join(', ') : value
        };
      }
    });
    const pathInfo = window.location.pathname.split('/')
    const logidUrl = window.location.href

    await submitForm({
      site: { id: currentSite.id },
      form: { id: formId },
      fieldValues: Object.values(fieldValues),
      formTypeSource: getFormTypeSource(pathInfo[1]),
      sourceId: pathInfo[2],
      trafficAccessReq: deviceInfo.bd_vid ? { ...deviceInfo, logidUrl } : undefined
    });
    if (typeof onSuccess === 'function') {
      onSuccess();
    }
    Toast.success('提交成功');
    onClose?.();
    setDisabled(false);

    // if (!isSSR) {
    //   setTimeout(() => {
    //     const query = querystring.parse(window.location.search.substr(1));
    //     // if(query.target) {
    //     //   window.open(query.target);
    //     // }

    //     // history.goBack();

    //     if (
    //       query.target &&
    //       (query.target.startsWith(window.location.origin) ||
    //         query.target.startsWith('//terminus-emp.oss-cn-hangzhou.aliyuncs.com'))
    //     ) {
    //       window.location.href = query.target;
    //     } else {
    //       history.goBack();
    //     }
    //   }, 1000);
    // }
  }

  function serializeConfig(config) {
    const fields = [];
    config.forEach((formFieldData) => {
      let rest = MAPPING[formFieldData.type] || { type: formFieldData.type };

      if (formFieldData.type === 'CHECKBOX' && formFieldData.displayForm === 'SELECT') {
        rest = {
          type: 'SELECT',
          customProps: {
            mode: 'multiple'
          }
        };
      }

      fields.push({
        ...rest,
        name: formFieldData.id,
        label: formFieldData.name,
        required: formFieldData.required === 'TRUE',
        tips: formFieldData.explain,
        customProps: {
          ...rest.customProps,
          placeholder: formFieldData.promptText,
          options: (formFieldData.fieldOptions || [])
            .map((opt) => ({
              label: opt,
              value: opt
            }))
            .concat(formFieldData.openOther === 'TURNON' ? [{ label: '其他', value: 'OTHER' }] : [])
        }
      });

      if (formFieldData.openOther === 'TURNON') {
        fields.push({
          type: 'TEXTAREA',
          name: `OTHER__${formFieldData.id}`,
          required: false,
          isRender: (values) => (values[formFieldData.id] || []).includes('OTHER')
        });
      }
    });

    return fields;
  }

  return (
    <div className={className}>
      <FormCreator disabled={disabled} config={serializeConfig(formConfig)} onSubmit={onSubmitHandle} />
    </div>
  );
}
