import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Fingerprint2 from 'fingerprintjs2sync';
import { isSSR } from 'scripts/utils';

const getQueryString = (name, history) =>  {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  const r = history?.location?.search?.substr(1).match(reg);
  if (r != null) {
      return unescape(r[2]);
  }
  return undefined;  
}

function getDeviceType() {
  if (isSSR) return -1;
  if(typeof navigator === 'undefined')return -1;
  const u = navigator.userAgent.toLowerCase();
  // 判断安卓设备（包含Android或Adr标识）
  const isAndroid = u.indexOf('android')  > -1 || u.indexOf('adr')  > -1;
  // 判断iOS设备（包含iPhone/iPad/iPod）
  const isIOS = (u.indexOf('iphone')  > -1 || u.indexOf('ipad')  > -1 || u.indexOf('ipod')  > -1) ;
  
  if (isAndroid) return 0;
  if (isIOS) return 1;
  return 2; // 其他设备 后台、Windows、MacOS、等属于PC
}

export default function useDeviceInfo() {
  const history = useHistory()
  const bd_vid = getQueryString('bd_vid', history)
  const ext_info = getQueryString('ext_info', history)

  const [deviceId, setDeviceId] = useState();
  const [deviceType, setDeviceType] = useState();

  useEffect(() => {
    if (isSSR) return -1;
    if(typeof navigator === 'undefined') return -1;
    const tempDeviceType = getDeviceType();
    setDeviceType(tempDeviceType)
    const tempDeviceId = (new Fingerprint2()).getSync().fprint;
    setDeviceId(tempDeviceId)

  }, []);

  return { bd_vid, ext_info, deviceType, deviceId };
}
